import moment from "moment";
// Returns true if an object is empty
//
// let object = {}
// isEmpty(array) // returns true
export const isEmpty = (obj) => Object.entries(obj).length === 0 && obj.constructor === Object;

// Returns the array of unique elements
//
// let array = [1, 2, 3, 3, 2, 1]
// unique(array) // returns [1, 2, 3]
export const unique = (array) => array.filter((item, index, arr) => arr.indexOf(item) === index);

// Find the indices of an array where a particular element is located.
//
// let array = ['a', 'b','a', 'b'];
// findIndices(array, 'a') // returns [0, 2]
// findIndices(array, 'b') // returns [1, 3]
//
export const findIndices = (array, element) => array.reduce((a, e, i) => (e === element ? a.push(i) && a : a), []);

// Returns the subarray of elements at given indices.
//
// let array = ['a', 'b','c', 'd'];
// filterByIndices(array, [0, 1]) // returns ['a', 'b']
// filterByIndices(array, [1, 3]) // returns ['b', 'd']
//
export const filterByIndices = (array, indices) => array.reduce((a, e, i) => (indices.includes(i) ? a.push(e) && a : a), []);

// https://stackoverflow.com/questions/3115982/how-to-check-if-two-arrays-are-equal-with-javascript
export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a === null || b === null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) if (a[i] !== b[i]) return false;

  return true;
};

// Sorts an array of objects based on an object property (column) that is a string.
//
// let array = [{name: 'Peter'}, {name: 'Brian'}, {name: 'Sean'}]
// sortByStringKey(array, 'name') // returns [{name: 'Brian'}, {name: 'Peter'}, {name: 'Sean'}]
export const sortByStringKey = (array, column) => array.sort((a, b) => {
  const x = a[column].toLowerCase();
  const y = b[column].toLowerCase();
  if (x < y) return -1;

  if (x > y) return 1;

  return 0;
});

// Returns a rounded percentage useful for display
//
// percentage(1, 3) // returns 33
export const percentage = (section, total) => Math.round(percentageRaw(section, total));

// Returns a floating point percentage useful for calculations
//
// percentageRaw(1, 3) // returns 33.333333
export const percentageRaw = (section, total) => (total > 0 ? (section / total) * 100 : 0);

export const secondsRemaining = (secondsElapsed, percentageComplete, result) => {
  if (result) return 0;
  if (percentageComplete === 0 || secondsElapsed === 0) return 60;

  return Math.round((secondsElapsed * (100 - percentageComplete)) / 100);
};

// Higher order function that takes a property name
//
// Returns a function for calculating the sum of a property in a collection of objects.
//
// E.G.
//
// let myCollection = [{a: 5}, {a: 2}, {a: 4}]
// const getSumA = getPropertySummer('a');
// getSumA(myCollection) // returns 11
export function getPropertySummer(property) {
  return (collection) => collection.reduce((total, current) => (total + current[property]), 0);
}

// Get the sum in an array of numbers
export const sum = (array) => (array.reduce((sum, x) => sum + x));

// Returns a "precise" location of a day in an array of Mondays
// This unusual function is used for determining where to position plotLines on a highchart where data is grouped by week.
//
export function dateIndexInArray(arrayOfMondays, dateToFind) {
  for (let i = 0; i < arrayOfMondays.length; i++) {
    if (dateToFind === arrayOfMondays[i]) return i;

    if (arrayOfMondays[i + 1]) {
      const dateToFindNumeric = Date.parse(dateToFind);
      const lower = Date.parse(arrayOfMondays[i]);
      const upper = Date.parse(arrayOfMondays[i + 1]);

      if (dateToFindNumeric > lower && dateToFindNumeric < upper) return i + ((dateToFindNumeric - lower) / (upper - lower));
    }
  }
}

// Returns true if the given value is a string
export const isString = (string) => (typeof string === 'string' || string instanceof String);

export const groupBy = (arr, property, collectionName = 'elements') => arr.reduce((groups, elem) => {
  let selectedGroup = groups.find(g => g[property] === elem[property]);
  if(!selectedGroup) {
    selectedGroup = {};
    selectedGroup[property] = elem[property];
    selectedGroup[collectionName] = [elem];
    groups.push(selectedGroup);
  } else {
    selectedGroup[collectionName].push(elem);
  }
  return groups;
}, []);

// This function computes RGB alpha value based on the input number.
export const computeRgbAlpha = (num, inMin, inMax) => (num - inMin) / (inMax - inMin) + 0;

export const formatDate = (date) => moment(date).format('DD MMM YYYY');

// eslint-disable-next-line arrow-body-style
export const buildDynamicUrl = (urlTemplate, params) => {
  // replace any :identifier sections in url template with params values
  // e.g. template - `/organisations/:orgId/learningGroups/:learningGroupId`, params {orgId: 1, learningGroupId: 3}
  // will return `/organisations/1/learningGroups/3
  return urlTemplate.replace(/:([a-zA-Z0-9_]+)/g, (_, key) => {
    const value = params[key];
    if (!value) throw new Error(`'${key}' param must be present`);
    return value;
  });
};

export const paramsFromUrl = (urlTemplate, url) => {
  const templateParts = urlTemplate.split('/');
  const urlParts = url.split('/');

  if (templateParts.length !== urlParts.length) throw new Error('URL structure does not match template');

  const params = {};

  templateParts.forEach((part, index) => {
    if (part.startsWith(':')) {
      const key = part.slice(1);
      params[key] = urlParts[index];
    }
  });

  return params;
};
