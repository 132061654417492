/* eslint-disable prefer-rest-params */
/* eslint-disable no-unused-vars */
// The purpose of this is to wrap axios calls and also centralize axios configuration and
// handle the requests/responses that being sent to/from backend.

import axios from 'axios';

const token = document.querySelector('[name=csrf-token]')?.content;
const tokenHeaderConfig = token ? { 'X-CSRF-TOKEN': token } : {};

const axiosClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...tokenHeaderConfig,
  },
});

// Note we can also set configuration dynamically for the client instance.
// e.g. axiosClient.defaults.timeout = <Some timeout that will apply for all the requests>
// Info: https://github.com/axios/axios#global-axios-defaults

// There is also a powerful mechanism call interceptors for axios client to 'tap' into HTTP request or response.
// See: https://github.com/axios/axios#interceptors

const client = {
  get: async function (url, config = {}) {
    return axiosClient.get(url, config);
  },
  post: async function (url, data, config = {}) {
    return axiosClient.post(url, data, config);
  },
  put: async function (url, data, config = {}) {
    return axiosClient.put(url, data, config);
  },
  patch: async function (url, data, config = {}) {
    return axiosClient.patch(url, data, config);
  },
  delete: async function (url, config = {}) {
    return axiosClient.delete(url, config);
  },
  isCancel: function (error) {
    return axios.isCancel(error);
  },
};

export default client;
